"use client"
import Icon from '@components/icon'
import styles from './history.module.scss'
import Stations from '@components/stations'
import { faHistory, faListOl } from '@fortawesome/free-solid-svg-icons'
import { useStorage } from '@hooks'
import { IStation } from '@models'
import React, { useEffect, useState } from 'react'

function HistoryComponent({allStations, isMobile}: {allStations: IStation[],isMobile: boolean}) {
    const { get: getHistory } = useStorage("history")
    const { get: getFavorites } = useStorage("favorites")
    const [stations, setStations] = useState<IStation[]>(allStations.slice(0, 10))
    const [title, setTitle] = useState('TOP 10 ברדיו')
    const [icon, setIcon] = useState(faListOl)
    useEffect(() => {
        const history = (getHistory() || []) as string[]
        const favorites = (getFavorites() || []) as string[]
        const stations = Array.from(new Set([...favorites, ...history]))
        
        if (stations?.length > 0) {
            const temp: IStation[] = []
            stations.forEach(prev => {
                const station = allStations.find(station => station.id === prev)
                if (station)
                    temp.push(station)
            })
            setTitle('שמעת לאחרונה')
            setIcon(faHistory)
            setStations(temp)
        }
    }, [allStations])

    return (
        <section className={styles.container}>
            {
                isMobile? (
                    <div className={styles.header}>
                        <div className={styles.icon}><Icon icon={icon} /></div>
                        <div className={styles.title}>
                            <h3>{title}</h3>
                            <p>{stations.length} תחנות</p>
                        </div>
                    </div>
                ): (
                    <h2>{title}</h2>
                )
            }
            <Stations stations={stations} size='md' align={isMobile? 'flex-start': 'center'} />
        </section>
    )
}

export default HistoryComponent