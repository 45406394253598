import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/logos/logo_mobile.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/logos/logo.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/home/home.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/banner/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/categories/categories.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/categories/desktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/history/component.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icon/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/search/index.tsx");
