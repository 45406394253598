"use client"
import React, { useEffect, useMemo, useState } from 'react'
import styles from './categories.module.scss'
import { CATEGORIES } from '@lib/helpers'
import Link from 'next/link'
import Icon from '@components/icon'
import { ICategory, IStation } from '@models'
import { useStorage } from '@hooks'
import Logo from '@components/station/logo'
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'

function Category({category, stations, active, setActive}: {category: ICategory, stations: IStation[], active: boolean, setActive: (category: string) => void}) {
    const [isClient, setIsClient] = useState(false)
    const { get } = useStorage("favorites")
    const favorites = useMemo(() => (typeof window !== 'undefined' ? (get() || []) : []) as string[], [get])

    useEffect(() => {
        setIsClient(true)
    }, [])
    
    if (category === 'favorites' && !isClient) return null

    return (
        <div id={category} className={`${styles.category} click ${active && styles.active}`} key={category} onClick={() => setActive(active? '': category)}>
            <div className={styles.header}>
                <div className={styles.icon}>
                    <Icon icon={CATEGORIES[category].icon} />
                </div>
                <div className={styles.title}>
                    <Link href={`/category/${category}`}>{CATEGORIES[category].name}</Link>
                    <p suppressHydrationWarning>{stations?.length || 0} תחנות רדיו</p>
                </div>
                <div className={styles.open}>
                    <Icon icon={active? faChevronCircleUp :faChevronCircleDown} />
                </div>
            </div>
            <nav className={`${styles.nav} animated-slow`} suppressHydrationWarning>
                {
                    ((active || category === 'top') && stations.length > 0) ? (
                        stations.map(station => (
                            <Link href={`/station/${station.id}`} key={station.id}>
                                <div className={styles.logo}>
                                    <Logo station={station} lazy />
                                    {
                                        favorites.includes(station.id) && (
                                            <Icon icon={['fas', 'heart']} />
                                        )
                                    }
                                </div>
                                <span>{station.name}</span>
                            </Link>
                        ))
                    ): (
                        <p><Icon icon={CATEGORIES[category].icon} /> לא נמצאו תחנות רדיו {CATEGORIES[category].name}</p>
                    )
                }
            </nav>
        </div>
    )
}

export default function CategoriesDesktop({categories, allStations}: {categories: { [key in ICategory]?: IStation[] }, allStations: IStation[]}) {
    const [showing, setShowing] = useState('')
    const { get } = useStorage("favorites")
    const favorites = useMemo(() => (typeof window !== 'undefined' ? (get() || []) : []) as string[], [get])
    const ordered: { [key: string]: IStation[] } = useMemo(() => {
        const order = ['favorites', ...Object.keys(CATEGORIES)]
        return order.reduce((acc, key) => {
            acc[key] = key === 'favorites'
                ? favorites
                    .map(id => allStations.find(station => station.id === id))
                    .filter(Boolean) as IStation[]
                : categories[key as ICategory] || []
            return acc
        }, {} as { [key: string]: IStation[] })
    }, [categories, favorites, allStations])
    
    return (
        <div className={`${styles.container} ${styles.desktop}`} suppressHydrationWarning>
            {
                Object.keys(ordered).map(category => (
                    <Category category={category as ICategory} stations={ordered[category]} key={category} active={showing === category} setActive={setShowing} />
                ))
            }
        </div>
    )
}